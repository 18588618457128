import React from 'react';

import { Layout } from '../modules/ui';
import ReferencesPageComponent from '../modules/references/ReferencesPage';
import Seo from '../components/SEO';
import * as Constants from '../constants';

const ReferencesPage = () => {
    return (
        <>
            <Seo pageId={Constants.pages.REFERENCES} />
            <Layout>
                <ReferencesPageComponent />
            </Layout>
        </>
    );
};

export default ReferencesPage;
